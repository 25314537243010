.video-tile{
  position: relative;
  background-color: rgba(black, .5);
  overflow: hidden;

  &:after{
    content: '';
    padding-top: 62.75%;
    display: table;
  }
  &--large:after{
    padding-top: 43%;
  }

  @include breakSm{
    &:after{
      padding-top: 62.75%;
    }
  };
  &:hover{
    .video-tile__overlay{
      background-color: rgba(black, .5);
    }
    .video-tile__thumbnail{
      transform: scale(1.03);
    }
  }

  &__overlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, .3);
    transition: background .5s;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakSm{
      opacity: 1 !important;
    };
  }
  &__title{
    font-family: 'GT America Compressed';
    text-transform: uppercase;
    font-size: 24px;
    position: absolute;
    left: 20px;
    bottom: 20px;

    @include breakSm{
      opacity: 1 !important;
    };
  }
  &__subtitle{
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 12px;
    opacity: 0;
    transition: opacity $default-transition;

    @include breakSm{
      opacity: 1 !important;
    };
  }
  &--center{
    .video-tile__title,
    .video-tile__subtitle{
      position: relative;
    }
    .video-tile__title{
      font-size: 40px;
      opacity: 0;
      left: auto;
      bottom: auto;
      transition: all .4s $default-transition-ease;
    }

    &:hover .video-tile__title{
      opacity: 1;
      transform: none;
    }
  }

  &:hover &__subtitle{
    opacity: 1;
  }

  &__thumbnail,
  &__video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &__video{
    z-index: 1;
  }
  &__thumbnail{
    z-index: 2;
    transition: transform $default-transition;
  }
}


// transitions

.page{
  .video-tile:not(.video-tile--centered){
    opacity: 0;
    transition: all $default-transition;
  }

  &.transition-enter-done{
    .video-tile{
      opacity: 1;
      transition-delay: .45s;
    }
  }
  &.transition-exit,
  &.transition-exit-active{
    .video-tile{
      opacity: 0;
    }
  }
}
