.pitch-pool__header{
  margin-left: $header-footer-padding;
  display: flex;
  align-items: flex-end;
  gap:20px;

  @include breakSm{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  };
}

.pitch-pool__title{
  font-size: 60px;
  line-height: 0.9em;

  @include breakSm{
    font-size: 30px;
  }
}

.pitch-pool__biography{
  max-width: 700px;

  p:first-child{
    margin-top: 0;
  }
  p:last-child{
    margin-bottom: 0;
  }
}

.pitch-pool__subheader{
  padding: 0;
  margin: 0;
  margin-left: $header-footer-padding;
  margin-top: 30px;
  margin-bottom: 30px;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 20px;

  a{
   text-decoration: none;
    opacity: 0.5;
    transition: opacity .3s;

    &:not(.active):hover{
      opacity: .7;
    }

    &.active{
      opacity: 1;
    }
  }
}

.page.pitch-pool{
  padding-top: 80px;

  .pitch-pool__navigation,
  .pitch-pool__title,
  .pitch-pool__biography,
  .pitch-pool__subheader{
    opacity: 0;
    transition: all $default-transition;
  }

  &.transition-enter-done{
    .pitch-pool__navigation,
    .pitch-pool__title,
    .pitch-pool__biography,
    .pitch-pool__subheader{
      opacity: 1;
    }
    .pitch-pool__title{
      transition-delay: .3s !important;
    }
    .pitch-pool__biography{
      transition-delay: .45s !important;
    }
    .pitch-pool__subheader{
      transition-delay: .6s !important;
    }
  }
  &.transition-exit,
  &.transition-exit-active{
    .pitch-pool__navigation,
    .pitch-pool__title,
    .pitch-pool__biography,
    .pitch-pool__subheader{
      opacity: 0;
    }
    .pitch-pool__navigation{
      transition-delay: .3s !important;
    }
    .pitch-pool__title{
      transition-delay: .45s !important;
    }
    .pitch-pool__biography{
      transition-delay: .55s !important;
    }
    .pitch-pool__subheader{
      transition-delay: .7s !important;
    }
  }
}
