.cursor{
  display: block;
  color: white;
  position: fixed;
  pointer-events: none;
  opacity: 0;
  font-size: 14px;
  text-shadow: 0 0 15px rgba(0,0,0,0.15);
  transform: scale(.5) translate(-50%, -50%);
  transition: opacity $default-transition--short,
              transform $default-transition--short;
}
