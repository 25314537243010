.play-bar{
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  z-index: 10;
  cursor: pointer;

  &__track{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    background-color: white;
    width: 0;
  }
  &__jump-to-label,
  &__current-label{
    @extend .font-gt-america-mono;
    position: absolute;
    bottom: 4px;
    color: white;
    margin-bottom: 16px;
    transform: translateX(-50%);
    transition: opacity $default-transition--short, transform $default-transition--short;
    pointer-events: none;
    opacity: 1;
  }
  &__jump-to-label{
    opacity: 0;
    transform: translateX(-50%) translateY(5px);

  }
  &.hovered .play-bar__jump-to-label{
    opacity: .5;
    transform: translateX(-50%);
  }
}

body.video-idle:not(.video-paused){
  .play-bar__current-label,
  .play-bar__jump-to-label{
    opacity: 0;
    transform: translateX(-50%) translateY(5px);
  }
}
