@import "Fonts";
@import "Page";
@import "base/variables";
@import "base/mediaqueries";

@import "pages/PitchPool";
@import "pages/Video";

@import "parts/Header";
@import "parts/PitchPoolNavigation";
@import "parts/PlayBar";
@import "parts/VideoTiles";
@import "parts/VideoTile";
@import "parts/VideoCursor";
@import "parts/VideoChangeControls";
@import "parts/PitchIntro";

html,body{
  color: white;
  font-size: 14px;
  font-family: 'GT America';
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  background-color: $dark-blue;
}
::-webkit-scrollbar{
  display: none;
}
a{
  color: inherit;
}
.app{
}
