$dark-blue: #011634;
$light-secondary: #F4F4F4;
$brand-red: #FF0000;

$break-md: 1440px;
$break-sm: 800px;
$break-xs: 420px;

$default-transition-ease: cubic-bezier(0.25, 1, 0.5, 1);
$default-transition: 1s $default_transition-ease;
$default-transition--short: .2s $default-transition-ease;

$base-transition-speed: .8s;

$header-footer-padding: 20px;
