%paused-video{
  transform: scale(0.9);
  opacity: 0.8;
  filter: saturate(0);
  transition: all 1s cubic-bezier(.455,.03,.515,.955);
}
.single-video{
  background-color: black;
  height: 100vh;
  transition: background-color $default-transition;

  .single-video__container.hovered{
    cursor: none;

    @media (hover: none) {
      cursor: default;
    }

    .cursor{
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  .video{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
    transition: all 0.5s cubic-bezier(.455,.03,.515,.955);


    @include breakSm{
      object-fit: contain;
    };

    &--paused{
      @extend %paused-video;
    }
  }
  .video__sound,
  .video__fullscreen{
    position: fixed;
    z-index: 20;
    padding: 10px;
    bottom: #{$header-footer-padding - 10px};
    cursor: pointer;
    user-select: none;
    transition: opacity $default-transition--short;

    .page-enter-done & {
      transition-delay: 0s !important;
    }

    &:hover{
      opacity: .5 !important;
      transition-delay: 0s !important;
    }
  }
  .video__sound{
    left: $header-footer-padding;
  }
  .video__fullscreen{
    right: $header-footer-padding;
  }
}


// transitions

.page.video{
  .single-video{
    opacity: 0;
    transition: opacity $base-transition-speed cubic-bezier(0.25, 1, 0.5, 1);
  }
  .video__sound,
  .video__fullscreen,
  .play-bar{
    opacity: 0;
    transition: opacity #{$base-transition-speed - .5s} cubic-bezier(0.25, 1, 0.5, 1);
  }

  &.transition-enter-done{
    .single-video,
    .video__sound,
    .video__fullscreen,
    .play-bar{
      opacity: 1;
    }
    .play-bar{
      transition-delay: .3s;
    }
    .video__sound,
    .video__fullscreen{
      transition-delay: .45s;
    }
  }
  &.transition-exit,
  &.transition-exit-active{
    .single-video,
    .video__sound,
    .video__fullscreen,
    .play-bar{
      opacity: 0;
    }
    .play-bar{
      transition-delay: .3s;
    }
    .single-video{
      transition-delay: .45s;
    }
  }
}
