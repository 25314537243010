@import "../base/variables";

.header{
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - #{$header-footer-padding * 2});
  margin-left: $header-footer-padding;
  margin-right: $header-footer-padding;
  margin-top: $header-footer-padding;
  color: $light-secondary;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo{
    display: inline-block;
    vertical-align: middle;

    svg{
      height: 21px;
      width: auto;
    }
  }
  .header__label{
    display: inline-block;
    vertical-align: middle;
    margin-left: $header-footer-padding;

    span.agency {
      color: $brand-red;
    }
  }

  .header__header-actions {
    transition: opacity $default-transition;

    .download-link {
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      transition: opacity $default-transition--short;

      &:hover{
        opacity: .5;
      }
    }
  }
}


// transitions
.page{
  .header{
    opacity: 1;
  }
  .header__header-actions{
    opacity: 0;
  }
  &.transition-enter-done{
    .header{
      opacity: 1;
    }
    .header__header-actions{
      opacity: 1;
      transition-delay: .45;
    }
  }
  &.transition-exit{
    .header{
      opacity: 0;
    }
  }
  &.transition-exit,
  &.transition-exit-active{
    .header__header-actions{
      opacity: 0;
    }
  }
}
