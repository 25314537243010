.video-tiles{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include breakSm{
    flex-direction: column;
  };

  .video-tile{
    width: 50%;

    @include breakSm{
      width: 100%;
    };

    &--large{
      width: 100%;
    }
  }
}
