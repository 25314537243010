.video-change-controls__container {
  position: absolute;
  top: 50%;
  width: calc(100% - #{$header-footer-padding * 2});
  margin-top: auto;
  margin-left: $header-footer-padding;
  margin-right: $header-footer-padding;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
}

.video-controls__next-video,
.video-controls__prev-video{
  opacity: 0.3;
  transform-origin: top right;
  transition: opacity 1s;
  cursor: pointer;
  padding: 20px;

  &:hover {
    opacity: 1;
    transition: opacity 1s;
  }
}

.video-controls__prev-video {
  margin-left: -20px;
  transform: rotate(90deg) translateY(40px) translateX(50%);
}

.video-controls__next-video{
  margin-right: -20px;
  transform: rotate(90deg) translateY(0) translateX(50%);
}
