@mixin breakMd {
  @media screen and (max-width: $break-md){
    @content;
  }
}

@mixin breakSm {
  @media screen and (max-width: $break-sm){
    @content;
  }
}

@mixin breakXs {
  @media screen and (max-width: $break-xs){
    @content;
  }
}
