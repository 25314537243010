@import "../base/variables";

.pitch-intro {
  transition: opacity 1.2s $default-transition-ease;
  z-index: 100;
  background-color: $dark-blue;
  box-sizing: border-box;

  &-enter {
    opacity: 0;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 1;
  }
  &-exit-done {
    opacity: 0;
    pointer-events: none;
    transition-delay: 1.5s;

    .pitch-intro__inner .logo,
    .pitch-intro__inner .title,
    .pitch-intro__inner .client,
    .pitch-intro__inner .description{
      opacity: 0 !important;
    }
    .pitch-intro__inner .description{
      transition-delay: 0s !important;
    }

    .pitch-intro__inner .client{
      transition-delay: .5s !important;
    }
    .pitch-intro__inner .title,
    .pitch-intro__inner .logo{
      transition-delay: 1s !important;
    }
  }
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 40px 140px;

  @include breakSm{
    padding: 20px 30px;
  }

  &__inner{
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    @include breakSm{
      justify-content: flex-end;
      align-items: stretch;
    };

    .logo,
    .title,
    .client,
    .description{
      opacity: 0;
      transition: opacity 1.2s $default-transition-ease;
    }
  }

  &--ready{
    .pitch-intro__inner{
      opacity: 1;

      .logo,
      .title,
      .client,
      .description{
        opacity: 1;
      }
      .client{
        transition-delay: .5s;
      }
      .description{
        transition-delay: 1s;
      }
    }
  }
  .logo{
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);
  }
  .title,
  .client {
    font-size: 120px;
    margin: 0;
    font-weight: 500;
    color: #fff;

    @include breakSm{
      font-size: 54px;
    };
  }
  .client {
    color: $brand-red;
  }
  .description {
    font-size: 19px;
    margin-top: 50px;

    @include breakSm{
      line-height: 1.4em;
      font-size: 12px;
      margin-top: 20px;
      margin-bottom: 0;
    };
  }
}
