@font-face {
  font-family: 'GT America';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/gt-america/GT-America-Light.woff') format('woff');
}
@font-face {
  font-family: 'GT America';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/gt-america/GT-America-Regular.woff') format('woff');
}
@font-face {
  font-family: 'GT America';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/gt-america/GT-America-Medium.woff') format('woff');
}
@font-face {
  font-family: 'GT America Compressed';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/gt-america/GT-America-Compressed-Medium.woff') format('woff');
}
@font-face {
  font-family: 'GT America Mono';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/gt-america/GT-America-Mono-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Akkurat Icons';
  src:  url('../fonts/akkurat-icons/Akkurat-Icons.eot?pyym8q');
  src:  url('../fonts/akkurat-icons/Akkurat-Icons.eot?pyym8q#iefix') format('embedded-opentype'),
    url('../fonts/akkurat-icons/Akkurat-Icons.ttf?pyym8q') format('truetype'),
    url('../fonts/akkurat-icons/Akkurat-Icons.woff?pyym8q') format('woff'),
    url('../fonts/akkurat-icons/Akkurat-Icons.svg?pyym8q#Akkurat-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cheltenham';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/cheltenham/CheltenhamStd-Light.woff') format('woff');
}
@font-face {
  font-family: 'Cheltenham';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/cheltenham/CheltenhamStd-LightItalic.woff') format('woff');
}
@font-face {
  font-family: 'Cheltenham';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/cheltenham/CheltenhamStd-Bold.woff') format('woff');
}



.font-gt-america-light{
  font-family: 'GT America';
  font-weight: 300;
}
.font-gt-america{
  font-family: 'GT America';
  font-weight: 400;
}
.font-gt-america-regular{
  @extend .font-gt-america;
}
.font-gt-america-medium{
  font-family: 'GT America';
  font-weight: 500;
}
.font-gt-america-compressed{
  font-family: 'GT America Compressed';
  font-weight: 500;
}
.font-gt-america-mono{
  font-family: 'GT America Mono';
  font-style: normal;
  font-weight: 400;
}
.font-cheltenham-light{
  font-family: 'Cheltenham';
  font-weight: 300;
}
.font-cheltenham-light-italic{
  @extend .font-cheltenham-light;
  font-style: italic;
}
.font-cheltenham-bold{
  font-family: 'Cheltenham';
  font-weight: 600;
}
.font-akkurat-icons{
  font-family: 'Akkurat Icons';
  font-weight: normal;
}
